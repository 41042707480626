<template>
  <div>
    <v-container class="form mt-10">
      <v-subheader class="title mb-4 text-uppercase pa-0">
        <v-icon class="mr-3" @click="$router.back()">mdi-arrow-left</v-icon>
        {{ id ? "Editar Mapa da Checagem" : "Novo Mapa da Checagem" }}
      </v-subheader>
      <v-divider />
      <v-card flat>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          autocomplete="off"
          @submit.prevent
        >
          <v-card-text>
            <v-card-title
              class="title font-weight-light text-uppercase"
            ></v-card-title>
            <v-container fluid>
              <v-row align="center">
                <v-col class="d-flex" cols="12" sm="12">
                  <v-autocomplete
                    v-model="dados.id_viajem"
                    :label="'Viajem'"
                    :rules="fieldRules"
                    item-text="descricao"
                    item-value="id"
                    required
                    :items="viajens"
                    @change="loadRoutes"
                  ></v-autocomplete>
                </v-col>
                <v-col class="d-flex" cols="12" sm="12">
                  <v-autocomplete
                    v-model="dados.id_localidade"
                    :label="'Localidade'"
                    :rules="fieldRules"
                    item-text="nome"
                    item-value="id"
                    required
                    :items="localidades"
                  ></v-autocomplete>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-footer>
            <div class="flex-grow-1"></div>
            <v-btn
              color="primary"
              depressed
              text
              :disabled="!valid"
              @click="validate"
              >Adicionar</v-btn
            >
          </v-footer>
          <v-card-text>
            <v-data-table :items="dadossalvos" :headers="headers">
              <template v-slot:item.action="{ item }">
                <v-icon small @click="deleteItem(item.id_mapa)" color="error"
                  >mdi-delete</v-icon
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-form>
      </v-card>
      <br />
      <br />
      <v-snackbar
        v-model="message.snacshow"
        :color="message.type"
        :multi-line="message.mode === 'multi-line'"
        :timeout="message.timeout"
        :vertical="message.mode === 'vertical'"
        bottom
      >
        {{ message.text }}
        <v-btn dark text @click="message.snacshow = false">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-snackbar>
    </v-container>
  </div>
</template>

<script>
export default {
  props: ["id"],
  data() {
    return {
      menu: false,
      showModal: false,
      loading: false,
      dadossalvos: [],
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        id_viajem: "",
        id_localidade: "",
      },
      viajens: [],
      localidades: [],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      headers: [
        { text: "Localidade", value: "nome", sortable: false },
        { text: "Ações", align: "right", value: "action", sortable: false },
      ],
      items: [],
    };
  },
  created() {
    if (this.id) {
      this.dados.id_viajem = this.id;
      this.$http(`/checartrechos/${this.id}`).then(
        (r) => (this.dadossalvos = r.data)
      );
    }
    this.$http("/localidades").then((r) => (this.localidades = r.data));
    this.$http("/viagens/full").then((r) => (this.viajens = r.data));
  },
  methods: {
    loadRoutes() {
      this.$http(`/checartrechos/${this.dados.id_viajem}`).then(
        (r) => (this.dadossalvos = r.data)
      );
    },
    clearFields() {
      this.linha = {
        id_localidade: 0,
        tempo: "",
        distancia_km: "",
      };
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      if (this.id) {
        this.$http
          .post(`/linhas/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros-operacionais/linhas/gerenciar"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/checartrechos/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.loadRoutes();
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
    deleteItem(id) {
      let c = confirm("Deseja apagar o registro?");
      if (c) {
        this.$store.dispatch("activeLoader", true);
        this.$http.post(`/checartrechos/delete/${id}`).then((r) => {
          this.$store.dispatch("activeLoader", false);
          alert(r.data.success);
          this.loadRoutes();
        });
      }
    },
  },
};
</script>

<style lang="css" scoped>
.form {
  width: 60rem;
}
@media only screen and (max-width: 60rem) {
  .form {
    width: 100%;
  }
}
</style>
